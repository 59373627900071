import React from "react";
import Logo from "../img/Asset1.png";
import tiktok from "../img/tiktok-brands.svg";
import logowhite from '../img/logowhite.png';
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

const SocialButtons = React.lazy(() => import("../SocialButtons"));
const Footer = inject('AuthStore')(observer((props) => {
  return (
    <div className="col-auto" style={{ backgroundColor: `${props.color}`, ...props.style }}>
      <div className="col-sm-12 ">
        <div className="row justify-content-center" style={{ paddingTop: "50px" }}>
          <div className="col-auto">
            <a href={props.links?.linktr ? props.links?.linktr : "https://linktr.ee/igotitapp"} target="#blank">
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-link"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href={props?.links?.facebook ? props.links?.facebook : "https://www.facebook.com/metabilia"} target="#blank">
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-facebook"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href={props?.links?.twitter ? props.links?.twitter : "https://twitter.com/metabilia_io"} target="#blank">
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-twitter"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href={props?.links?.instagram ? props.links?.instagram : "https://www.instagram.com/metabilia_io/"} target="#blank">
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-instagram"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href={props?.links?.linkedin ? props.links?.linkedin : "https://www.linkedin.com/company/igotitapp/mycompany/?viewAsMember=true"}
              target="#blank"
            >
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-linkedin-in"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href={props?.links?.tumblr ? props.links?.tumblr : "https://www.tiktok.com/@igotitauction?lang=en"}
              target="#blank"
            >
              <SocialButtons
                data={{ width: "40px", height: "40px", size: "16px" }}
                icon="fa fab fa-tumblr"
              />
            </a>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 py-5 ">
            <div className="row justify-content-center">

            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 py-5 mt-3">
                <a href="#">
                  <div className="row justify-content-center">
                    <img
                      src={logowhite}
                      alt=""
                      className="logo"
                      style={{
                        width: "150px",
                        height: "100%",
                        marginTop: "12px",
                      }}
                    />
                  </div>
                </a>
                <div className="col mt-4">
                  <Link style={{ textDecoration: 'none' }} to={`/platforms/${props.AuthStore.company_url_name}/terms_and_conditions`}> <h2 className="text-white mb-3" style={{ fontSize: '8px' }}>TERMS & CONDITIONS</h2></Link>
                  <Link style={{ textDecoration: 'none' }} to={`/platforms/${props.AuthStore.company_url_name}/faq`}> <h2 className="text-white mb-2" style={{ fontSize: '8px' }}>FAQ</h2></Link>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}));
export default Footer;
