
import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import CompanyPropsHelper from '../../../../helpers/CompanyPropsHelper';
import RequestDefaultsHelper from '../../../../helpers/RequestDefaultsHelper';
import { Link } from "react-router-dom";
import Logo from "../img/Asset1.png"

@inject("AuthStore")
@inject("UserStore")
@inject("StripeStore")
@inject("CompanyStore")
@observer

class PlatformHeader extends Component {
  onImgError = (e) => {
    e.target.src = Logo;
  }
  state = {
    company_name: "",
    companydetail: {},

  }
  capitalize_first_letter(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }
  }


  render() {

    const color_text = this.props.bgcolor == "#ffffff" ? 'black' : 'white'

    return (
      <header id="headerBgColor" style={{ backgroundColor: `${this.props.bgcolor}` }}>
        {/* <figure className="brand">
          <Link to={this.props.link || '#'} >
            <img id="headerImage" src={`${this.props.Logo}`} onError={this.onImgError} alt="" />
          </Link>
          <div>
            <h5 className="p-0">
              <span
                style={{ backgroundColor: 'rgb(55, 80, 115)', color: '#fff', margin: '15px 15px 0px' }}
                class="badge badge-secondary"
              >
                BETA
              </span>
            </h5>
          </div>
        </figure> */}

        <figure className="brand" style={{ marginLeft: "5%" }}>
          <Link to={this.props.link || '#'} className="d-flex align-items-center">
            <div>
              <img style={{ width: "75px" }} id="headerImage" src={`${this.props.Logo}`} alt="" />{' '}
            </div>
            <div>
              <h5 className="p-0">
                <span
                  style={{ backgroundColor: 'rgb(55, 80, 115)', color: '#fff', margin: '15px 15px 0px' }}
                  class="badge badge-secondary"
                >
                  BETA
                </span>
              </h5>
            </div>
          </Link>
        </figure>

        {/* <figure className="brandnew">
          <Link to="/" className="d-flex align-items-center">
            <div>
              <img src={this.props.AuthStore.dark_model_toggle ? NewLogo : LightLogo} alt="" className="brandnew" />{' '}
            </div>
          
          </Link>
        </figure> */}


        <div className="col">
          <nav className="menu" >
            <input type="checkbox" id="menuToggle" />
            <label htmlFor="menuToggle" style={{ backgroundColor: `${this.props.drpColor}` }} className="menu-icon"><i className="fa fa-bars"></i></label>
            <ul >
              {this.props.AuthStore.isAuthenticated === true && this.props.CheckPortfolio === true ? (
                <>
                  <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/portfolio`}>My Portfolio</Link>
                </>
              ) :
                null
              }
              {this.props.AuthStore.isAuthenticated === true ? (
                <>
                  <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}` }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/myitems`}>My History</Link>
                  {this.props.CompanyStore.market_place &&
                    <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}` }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/mycurrent_history`}>My Current Activity</Link>
                  }
                </>
              ) :
                null
              }
              {this.props.CompanyStore.market_place &&
                <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/marketplace`}>Marketplace</Link>
              }

              {this.props.CompanyStore.market_place &&
                <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}` }} to={`/analytics/${this.props.company_name || this.props.AuthStore.company_url_name}`}>Analytics</Link>
              }

              <div className="dropdown" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }}>
                {this.props.AuthStore.isAuthenticated === true ? (
                  <Link to="#" style={{ fontSize: '15px', fontWeight: 'bolder', backgroundColor: `${this.props.drpColor}`, color: color_text }} className="dropbtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >{this.props.AuthStore.user.user_name}</Link>
                ) :
                  <Link to="#" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} onClick={() => this.props.AuthStore.setModal_name("signin")} >Sign In</Link>
                }
                <div className="dropdown-content lists" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }}>

                  {this.props.AuthStore.isAuthenticated === true ? (
                    <>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to="#" onClick={() => this.props.AuthStore.setModal_name("edit")}>Personal Information</Link>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/mycards`}>Payment Info</Link>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to="#" onClick={() => this.props.AuthStore.setModal_name("shipping")}>Shipment Details</Link>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/faq`}>FAQ</Link>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to={`/platforms/${this.props.company_name || this.props.AuthStore.company_url_name}/terms_and_conditions`}>Terms & Conditions</Link>
                      <Link className="bar" style={{ backgroundColor: `${this.props.drpColor}`, color: color_text }} to="#" onClick={() => this.props.AuthStore.logout(sessionStorage.getItem("company_name"))} >Logout</Link>
                    </>
                  )
                    : null}

                </div>
              </div>

            </ul>
          </nav>
        </div>

      </header >

    )
  };
}
export default PlatformHeader;
