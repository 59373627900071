import { observable, action } from "mobx";
import { post } from "../services/ajax.service";

class BidStore {

    @observable Bid = [];
    @observable errors = "";

    @action getBid = async (data,options={}) => {
        this.Bid = [];
        this.errors = "";
        try {
            let response = await post(
                `/bids/new.json`, data,options
            );
            this.errors="";
             this.Bid = response.data.event_items;
        } catch (err) {
            this.errors = err.response.data.errors[0]
            // console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

}

export default BidStore;
