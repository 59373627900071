import React, { useEffect, useState,useContext } from 'react';
import CountDown from '../CountDown';
import { ButtonTitleHelper } from '../../../../../helpers/buttontitle.helper';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

const ItemCard = ({
  index,
  video = "",
  image,
  logo,
  deadline,
  price,
  title,
  itemDetailUrl = '',
  colors = { body: '#BA0101', secondary: '#022163', textColors: '#fff' },
  addToWishlist,
  added,
  skuNumber,
  Quantity,
  masterQuantity,
  Membership,
  key,
  style,
  AuthStore,
  description,
  itemType,
  previewAsset,
  channel_name,
  
}) =>{



  const [bidPrice, setBidPrice] = useState(price);
  const [bidClose_at, setClose_at] = useState(deadline);
  // const [auth,setAuth]=useState(AuthStore.isAuthenticated);
  

  useEffect(() => {
    
    (async () => {
    console.log(AuthStore, "Auth");
    console.log(AuthStore.isAuthenticated ,"after DATA");

    
    if (itemType == 'Bid Only' && AuthStore.isAuthenticated) {


      
      //here is fired on component did mount.
      const channel = AuthStore.pusher.subscribe(channel_name);
      const get_channel_name = `${channel_name}_bid_placed`;
      channel.bind(get_channel_name, (data) => {

        const bid = data.data.bid.price;
        setBidPrice(bid);

        if (data.data?.closes_at) {
          let close_time = new Date(data.data?.closes_at * 1000)
          setClose_at(close_time);
        }
      });
      return () => {
        //here is fired on component unmount.
        
      };
    }
  })(1);
  }, [])

  const Loading = () => (
    <div className="post loading">
      <h5 style={{ marginTop: "50%" }}> Loading...</h5>
    </div>
  )


  return (
    <div className="marketplace__item" style={style}>
      {/* <Lazyload key={key} placeholder={<Loading />} offset={-400}> */}
      <div style={{ position: 'relative' }}>
        {video ? (
          <video className="DynamicVideo" autoPlay playsInline muted loop src={video} />
        ) : (<img src={image} alt={title} className="item__image" />)}

        {AuthStore?.isAuthenticated && (
          <span tooltip={added ? 'Remove from Wishlist' : 'Add to Wishlist'} flow="right">
            <i className={`fa fa-heart${added ? '' : '-o'}`} aria-hidden="true" onClick={addToWishlist}></i>
          </span>
        )}
      </div>
      {/* </Lazyload> */}
      <div className="d-flex flex-column justify-content-between align-items-center">
        {logo ? <img src={logo} alt={title} className="logo" /> : null}
        <h4 className="text-uppercase " style={{ color: "black" }}>
          {title}
        </h4>
        {skuNumber && masterQuantity ? (
          <>
            {Membership == false ? (
              <div className="p mt-2" >{`#${skuNumber} of ${masterQuantity}`}</div>
            ) :
              <div className="p mt-2" >{`#${skuNumber}`}</div>
            }
          </>
        ) : null}
        {/* {description ? (
          <div>
            <p
              style={{
                display: '-webkit-box',
                '-webkit-line-clamp': '6',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                // textOverflow: 'ellipsis',
                textAlign: 'center',
                padding: '1rem',
                margin: '1rem',
              }}
            >
              {description}
            </p>
          </div>
        ) : null} */}
        <div>
          {itemType == 'Bid Only' ? <p>Current Bid</p> : null}
          {itemType == 'Buy Only' ? <p>Buy it now price</p> : null}
          {itemType == 'Raffle' ? <p>Entry Fee</p> : null}

          {price == "Free" ? (
            <p className="price" style={{ color: 'black', display: 'inline-block' }}>{itemType == "Bid Only" ? bidPrice : price}</p>
          ) :
            <p className="price" style={{ color: 'black', display: 'inline-block' }}>${itemType == "Bid Only" ? bidPrice : price}</p>
          }


        </div>
        <div>
          <p style={{ display: 'inline-block' }}>Time Remaining</p>
          <CountDown style={{ color: 'black' }} deadline={itemType == "Bid Only" ? bidClose_at : deadline} />
        </div>


        <Link
          className="btn btn-lg text-uppercase text-bold DynamicButton"
          // style={{ backgroundColor: colors.secondary, color: colors.textColors, marginBottom: '10px' }}
          style={{ backgroundColor: "black", color: "white", marginBottom: '10px' }}
          to={itemDetailUrl}
        >
          {ButtonTitleHelper(itemType)}
        </Link>
      </div>
    </div>
  )
};

export default ItemCard;
