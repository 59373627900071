import axios from 'axios'
require("dotenv").config();

const axiosInstance = axios.create({
baseURL: process.env.REACT_APP_BASE_URL,
headers: {"Api-Key": process.env.REACT_APP_API_KEY, "Os": "Web"}
})

// axiosInstance.interceptors.request.use(function (config) {
//     //config.data = {...config.data, ...{"api_key": process.env.REACT_APP_API_KEY}}    
//     config.headers= {...config.headers, ...{"Api-Key": process.env.REACT_APP_API_KEY}}
    
//     return config
// }, function (err) {
//   return Promise.reject(err)
// }
// )

export default axiosInstance;

