import { observable, action } from "mobx";
import { post } from "../services/ajax.service";


class StripeStore {
    @observable error = "";
    @action getStripe = async (data) => {
        try {

            await post(`/stripes/add_card.json`, {stripe_token:data.id});
        }
        catch (err) {
            console.log(err, "error");
        }
    };
    @action setDefault = async (data) => {
        try {

           let response= await post(`/stripes/default_card.json`, data);

           this.error=response.data.message;

        }
        catch (err) {
            console.log(err, "error");
            this.error=err.response.data.errors[0];
        }
    };
    @action deleteCard = async (data) => {
        try {

            let response= await post(`/stripes/delete_card.json`, data);

            this.error=response.data.message;
        }
        catch (err) {
            this.error=err.response.data.errors[0];
            console.log(err, "error");
        }
    };

}

export default StripeStore;

