import numeral from "numeral";
import moment from "moment";
const NumeralHelper = (data,type) => {

    switch(type) {
        case 'money':
          data = numCheck(data);
          return "$"+numeral(data).format('0,0.00');
        case 'small-money':
          data = numCheck(data);
          return "$"+numeral(data).format('0.00');
        case 'percentage':
          data = numCheck(data);
            return Math.round(data)+"%";
        case 'date':
            return moment(data).format("MM-DD-YYYY")
        case 'countable':
          data = numCheck(data);
            return parseInt(data)
        case 'phone':
            return formatPhoneNumber(data)
        case 'large-money':
          data = numCheck(data);
          return '$'+numeral(data).format("0,0.00")
        case 'large-countable':
          data = numCheck(data);
          return numeral(data).format('0,0')

        case `money-absolute`:
          data = numCheck(data);
          if(data < 0) {
            return `($${numeral(Math.abs(data)).format("0,0")})`
          }
          else {
            return '$'+numeral(data).format("0,0")
          }
        default:
          return data
      }
}



let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return str
};

function numCheck(data) {
  if(!isFinite(data)){
    return 0;
  }
  else if(isNaN(data)){
    return 0
  }
  return data
}
export default NumeralHelper;