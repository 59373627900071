import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Decoding } from '../../../helpers/CodingHelper';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import pic2 from '../Design/img/buck3.png';
import pic3 from '../Design/img/bucks.png';
import pic4 from '../Design/img/bucks2.png';
import f2 from '../Design/img/buck3.png';
import CompanyPropsHelper from '../../../helpers/CompanyPropsHelper';
import RequestDefaultsHelper from '../../../helpers/RequestDefaultsHelper';
import FeaturedCarousel from './Components/featuredeventscarousel';
import ZoomCarousel from './Components/ZoomCarousel/ZoomCarousel';
import PageLayout from './Components/PageLayout/PageLayout';
import GtagHelper from '../../../helpers/GtagHelper';

require('dotenv').config();
const Carousel = React.lazy(() => import('./Components/carousel.jsx'));
const SignInModal = React.lazy(() => import('../Design/Components/signInModal'));

@inject('EventStore')
@inject('CompanyStore')
@inject('AuthStore')
@observer
class Platforms extends Component {
  state = {
    api: '',
    loading: false,
    companydetail: {},
    videoPlay: false,
    videoMute: true,
  };

  addDefaultSrc = (ev) => {
    ev.target.src = f2;
  };

  componentDidMount() {
    this.props.AuthStore.setCompanyUrlName(this.props?.match?.params?.company_name ? this.props.match.params.company_name : "igi");
    CompanyPropsHelper(this.props.match.params.company_name, true).then(async (data) => {
      GtagHelper(this.props.match.params.company_name)
      RequestDefaultsHelper(data.url, data.api_key, this.props.match.params.company_name, data.company_data);
      this.setState({ companydetail: data.company_data });
      this.setState({ loading: true });
      await this.props.EventStore.getCarouselEventItems();
      await this.props.EventStore.getLiveEvents();
      // await this.props.EventStore.getFeaturedEvents();
      this.props.CompanyStore.market_place = data.company_data.company.show_marketplace;
      this.setState({ loading: false });


      // let x = document.getElementsByClassName("MsoNormal");
      // if (x.length > 0) {
      //   x[0].style.fontSize = "20pt";
      // }
      // console.log(x[0]);

    });
  }

  VideoPlay_button = () => {
    console.log("hello1");
    var video = document.getElementById("videoplay");
    if (this.state.videoPlay) {
      video.pause();
      this.setState({ videoPlay: false });
      console.log("hello");
      return
    }
    video.play();
    this.setState({ videoPlay: true });
  }
  VideoMute_button = () => {
    var video = document.getElementById("videoplay");
    if (this.state.videoMute) {
      video.muted = true;
      this.setState({ videoMute: false });
      return
    }
    video.muted = false;
    this.setState({ videoMute: true });
  }
  render() {

    const FeaturedVideoUrl = this.state.companydetail?.company?.featured_video?.featured_video?.url
    let link = this.props.match.params.company_name || sessionStorage.getItem('company_name');
    if (link.includes("igi")) {
      link = null
    }
    const headerProps = {
      body_color: this.state.companydetail?.company?.body_color,
      btcolor: this.state.companydetail?.company?.button_text_color
        ? this.state.companydetail.company.button_text_color
        : '#00471b',
      bgcolor: this.state.companydetail?.company?.primary_color,
      Logo: this.state.companydetail?.company?.logo?.logo?.url,
      bcolor: this.state.companydetail?.company?.button_color
        ? this.state.companydetail.company.button_color
        : '#00471b',
      drpColor: this.state.companydetail?.company?.primary_color || 'black',
      CheckPortfolio: this.state.companydetail?.company?.show_myportfolio,
      link: link ? `/platforms/${link}` : '/',
    };
    const footerProps = {
      color: this.state.companydetail?.company?.primary_color || 'black',
      links: this.state.companydetail?.company
    };

    return (
      <PageLayout headerProps={headerProps} footerProps={footerProps} loading={this.state.loading}>
        <ZoomCarousel
         AuthStore= {this.props.AuthStore}
          items={this.props.EventStore.carouseleventitems}
          logo={this.state.companydetail?.company?.background_image?.background_image?.url}
          company_url_name={this.props.AuthStore.company_url_name}
          colors={{
            // body: this.state.companydetail?.company?.body_color,
            secondary: this.state.companydetail?.company?.secondary_color,
            primary_color: this.state.companydetail?.company?.primary_color,
            textColors: this.state.companydetail?.company?.button_text_color,
          }}
        />

        {FeaturedVideoUrl ? (
          <section>
            <div className="container text-center" style={{ maxWidth: "1518px" }}>
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-12 text-left">
                  <div className={`${this.state.companydetail?.company?.body_color == "#000000" ? "white-text" : "black-text"}`}>
                    <div className=" font-class text-left" dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.top_text }} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col featured_video_adjustment">
                    <div onClick={() => this.VideoMute_button()}>
                      <i className={this.state.videoMute == true ? "fa fal  fa-volume-up mute_button" : "fa fal fa-volume-mute mute_button"}
                      ></i>
                    </div>
                    <div onClick={() => this.VideoPlay_button()}>
                      <i className={this.state.videoPlay == false ? "fa fab fa-play play_button" : ""}
                      ></i>
                    </div>
                    <video
                      onClick={() => this.VideoPlay_button()}
                      loop
                      className="video_main_page"
                      src={FeaturedVideoUrl}
                      type="video/mp4"
                      id="videoplay"
                      style={{ cursor: "pointer", boxShadow: "rgb(0 0 0 / 59%) 8px 7px 6px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) :

          <div className={`${this.state.companydetail?.company?.body_color == "#000000" ? "white-text" : "black-text"}`}>
            <div className=" font-class text-left" dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.top_text }} />
          </div>
        }


        {/* <div className="row justify-content-center">
          <div className="col-md-6">

          </div>
          <div className="col-md-6">
            <div className="col" style={{ position: "relative" }}>
              <div onClick={() => this.VideoMute_button()}>
                <i className={this.state.videoMute == true ? "fa fal  fa-volume-up mute_button" : "fa fal fa-volume-mute mute_button"}
                ></i>
              </div>
              <div onClick={() => this.VideoPlay_button()}>
                <i className={this.state.videoPlay == false ? "fa fab fa-play play_button" : ""} aria-hidden="true"
                ></i>
              </div>
              <video
                onClick={() => this.VideoPlay_button()}
                loop
                className="video_main_page"
                src={FeaturedVideoUrl}
                type="video/mp4"
                id="videoplay"
                style={{ cursor: "pointer", boxShadow: "rgb(0 0 0 / 59%) 8px 7px 6px" }}
              />
            </div>
          </div>
        </div> */}

        <div className={!FeaturedVideoUrl && !this.state.companydetail?.company?.top_text ? "eventsContainerConditional" : "eventsContainerConditional"}>
          {this.props.EventStore.events.map((ev) => (
            <div className="cardBody shadow-lg">
              <Link to={`/platforms/${this.props.AuthStore.company_url_name}/events/${ev.id}`}>
                <div>
                  <img src={ev.photo.photo.url} onError={this.addDefaultSrc} alt={ev.title} />
                </div>
                <h5 style={{ color: "black" }}>{ev.title}</h5>
                <p style={{ fontSize: "15px" }}>{ev.description} </p>
              </Link>
            </div>
          ))}
        </div>
        <div className={`${this.state.companydetail?.company?.body_color == "#000000" ? "white-text" : "black-text"}`}>
          <div className=" font-class text-left backend_text" dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.bottom_text }} />
        </div>
      </PageLayout>
    );
  }
}

export default Platforms;
