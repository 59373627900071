import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './helpers/PrivateRoute';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { AddCard } from './views2/dashboard/Design/Components/AddCard';
import { observer, inject } from 'mobx-react';
import Zendesk from "react-zendesk";

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
const SignInModal = React.lazy(() => import('./views2/dashboard/Design/Components/signInModal'));
const EditModal = React.lazy(() => import('./views2/dashboard/Design/Components/EditModal'));
const ShippingAddress = React.lazy(() => import('./views2/dashboard/Design/Components/ShippingAddressModal'));
const DefaultLayout = React.lazy(() => import('./views2/dashboard/DefaultLayout'));

const loading = () => (
  <div className="lds-ellipsis lds-ellipsis--center">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
const Design = React.lazy(() => import('./views2/dashboard/Design/dashboard'));
const FeaturedEvents = React.lazy(() => import('./views2/dashboard/Design/featuredEvents'));
// const MarketPlace = React.lazy(() => import("./views2/dashboard/Design/itemsDetail"))
const MarketPlace = React.lazy(() => import('./views2/dashboard/Design/Marketplace'));
const EventItems = React.lazy(() => import('./views2/dashboard/Design/eventItems'));
const Auctions = React.lazy(() => import('./views2/dashboard/Design/auctions'));
const ItemsDetail = React.lazy(() => import('./views2/dashboard/Design/itemsDetail'));
const Platforms = React.lazy(() => import('./views2/dashboard/Design/Platforms'));
const Recovery = React.lazy(() => import('./views2/auth/Login/Recovery'));
const Signup = React.lazy(() => import('./views2/auth/Login/SignUp'));
const Page404 = React.lazy(() => import('./views2/404'));
const Faq = React.lazy(() => import('./views2/dashboard/Design/Faq'));
const TermAndConditions = React.lazy(() => import('./views2/dashboard/Design/TermAndConditions'));
const MarketData = React.lazy(() => import('./views2/dashboard/Design/Marketdata'));

@inject('AuthStore')
@inject('UserStore')
@inject('StripeStore')
@observer
class App extends Component {
  handleSubmit = async (payment) => {
    await this.props.StripeStore.getStripe(payment);
    await this.props.AuthStore.getPreferences();
    await this.props.UserStore.getCreditCards();
    this.props.AuthStore.setModal_Popup(false);
  };
  state = {
    url: false,
  }
  componentWillUnmount() {
    if(this.props.AuthStore.pusher) {
      this.props.AuthStore.pusher.disconnect()
      
    }
  }
  componentDidMount() {
    // console.log("this.props.location.pathnamehref", window.location.pathname)
    window.location.href = `https://metabilia.io${window.location.pathname}`
    
    if (window.location.href.includes('#showsignup')) {
      this.props.AuthStore.setModal_Popup_2(true);
    }
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);
        ReactPixel.pageView();
      });
  }

  render() {
    const setting = {
      color: {
        theme: "#6abcf8"
      },
      launcher: {
        label: {
          "*": " "
        },
        chatLabel: {
          "en-US": "Need Help"
        }
      },
      contactForm: {
        fields: [
          { id: "description", prefill: { "*": "My pre-filled description" } }
        ]
      }
    };
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <Modal isOpen={this.props.AuthStore.modal_close}>
            <ModalHeader toggle={() => this.props.AuthStore.setModal_Popup(false)} />
            {this.props.AuthStore.isAuthenticated && (
              <>
                {this.props.AuthStore.modal_name == 'shipping' && (
                  <ShippingAddress onModalClose={this.props.AuthStore.setModal_Popup} />
                )}
                {this.props.AuthStore.modal_name == 'card' && <AddCard handleSubmit={this.handleSubmit} />}
                {this.props.AuthStore.modal_name == 'edit' && <EditModal />}
              </>
            )}
            {this.props.AuthStore.modal_name == 'signin' && (
              <SignInModal onModalClose={this.props.AuthStore.setModal_Popup} />
            )}
          </Modal>
          {this.props.AuthStore.modal_close_2 ?
            <Modal isOpen={window.location.href.includes("#showsignup") == !this.props.AuthStore.isAuthenticated}>
              <ModalHeader toggle={() => this.props.AuthStore.setModal_Popup_2(false)} />
              {window.location.href.includes("#showsignup") && !this.props.AuthStore.isAuthenticated ? (

                <SignInModal onModalClose={this.props.AuthStore.setModal_Popup_2} />

              ) : null}
            </Modal>
            : null}
          <Zendesk
            zendeskKey={ZENDESK_KEY}
            {...setting}
            onLoaded={() => console.log(" ")}
          />

          <Switch>

            <Route
              exact
              path="/platforms/:company_name/faq"
              name="Faq"
              render={(props) => <Faq {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name/terms_and_conditions"
              name="TermAndConditions"
              render={(props) => <TermAndConditions {...props} />}
            />
            <Route
              exact
              path="/events/:id/:api"
              name="ItemsDetail"
              render={(props) => <ItemsDetail {...props} />}
            />
            <Route exact path="/platforms/:company_name/marketplace" name="MarketPlace" render={(props) => <MarketPlace {...props} />} />
            <Route
              exact
              path="/platforms/:company_name/items/:item_type_id/:event_item_id"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
            <Route
              exact
              path="/platforms/:company_name"
              name="Platforms"
              render={(props) => <Platforms {...props} />}
            />
            <Route exact path="/platforms/:company_name/events/:id" name="ItemsDetail" render={(props) => <ItemsDetail {...props} />} />
            <Route
              exact
              path="/platforms/:company_name/items/:item_type_id/:event_item_id/:marketplace"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
            <Route
              exact
              path="/items/:item_type_id/:event_item_id"
              name="Auctions"
              render={(props) => <Auctions {...props} />}
            />
            <Route exact path="/design/event_items" name="EventItems" render={(props) => <EventItems {...props} />} />
            <Route
              exact
              path="/featured_events"
              name="FeaturedEvents"
              render={(props) => <FeaturedEvents {...props} />}
            />

            <Route
              exact
              path="/analytics/:company_name"
              name="MarketData"
              render={(props) => <MarketData {...props} />}
            />

            {/* {process.env.REACT_APP_ENVIRONMENT == "production" ? (
              <Route exact path="/" name="Design Page" component={() => window.location.href = "https://igotitapp.com"} />
            ) : null} */}
            <Route exact path="/" name="Design Page" render={(props) => <Design {...props} />} />
            <Route exact path="/signup" name="SignUp Page" render={(props) => <Signup {...props} />} />
            <Route exact path="/recovery" name="Recovery" render={(props) => <Recovery {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            {/* <PrivateRoute  path="/sources/create" name="Sources Create" component={SourcesCreate}/>
            <PrivateRoute  path="/sources" name="Sources Index" component={SourcesIndex}/> */}
            <Redirect to="/" />
            {/* <PrivateRoute  path="/sources/create" name="Sources Create" render={props => <SourcesIndex {...props} />}
            <PrivateRoute  path="/sources" name="Sources Index"  render={props => <SourcesIndex {...props} />} */}
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
