import React from 'react';
import Header from '../PlatFormHeader';
import Footer from '../Footer';
import BlockPageSpinner from '../BlockPageSpinner/BlockPageSpinner';

import './PageLayout.scss';

const PageLayout = ({ className, headerProps, footerProps, loading, children }) => {
  return (
    <div className={`pageLayout ${className || ''}`}>
      <BlockPageSpinner loading={loading} />
      <Header {...headerProps} />
      <div className="general__padding" style={{ backgroundColor: `${headerProps.body_color}` }}>{children}</div>
      <Footer style={{ marginTop: 'auto' }} {...footerProps} />
    </div>
  );
};

export default PageLayout;
