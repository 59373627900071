import TagManager from 'react-gtm-module'
export default function(company_name) {
    if(allowed_companies.includes(company_name)) {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTAG_SAINTS,
            dataLayer: {
                js: new Date(),
            }
        }

        TagManager.initialize(tagManagerArgs)
    }   

}

let allowed_companies = ["saints"]