import { observable, action } from "mobx";
import { get, post } from "../services/ajax.service";
import { createContext } from 'react'

class EventStore {
    @observable events = [];
    @observable featuredevents = [];
    @observable eventitems = [];
    @observable buyitems = {};
    @observable eventName = {};
    @observable eventitemsdetails = [];
    @observable carouseleventitems = [];
    @observable filterDuplicationRemove = [];
    @observable Tempeventitems = [];
    @observable errors = "";
    @observable upcomingevents = [];
    @observable latest_purchased_items = [];

    @observable mainwebappdata = {};





    @action getEventName = async (data, options = {}) => {
        this.eventName = {};
        try {
            let response = await get(
                `/events.json?id=${data.id}&show_closed=${false}`, options);

            this.eventName = response.data.event;
        } catch (err) {
            console.log(err);
        }
    }

    @action sortby(filter, type = "A") {
        if (this.eventitems.length > 0) {
            if (filter == 1 && type == "A") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) < parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return -1;
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) > parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return 1;
                    return 0
                })
            }
            if (filter == 2 && type == "A") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.closes_at) < parseInt(b.closes_at)) return -1;
                    if (parseInt(a.closes_at) > parseInt(b.closes_at)) return 1;
                    return 0
                })
            }
            if (filter == 1 && type == "D") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) > parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return -1;
                    if (parseInt(a.item_type == "Bid Only" ? a.current_bid?.price ? a.current_bid?.price : a.price : a.price) < parseInt(b.item_type == "Bid Only" ? b.current_bid?.price ? b.current_bid?.price : b.price : b.price)) return 1;
                    return 0
                })
            }
            if (filter == 2 && type == "D") {
                this.eventitems = this.eventitems.slice().sort(function (a, b) {
                    if (parseInt(a.closes_at) > parseInt(b.closes_at)) return -1;
                    if (parseInt(a.closes_at) < parseInt(b.closes_at)) return 1;
                    return 0
                })
            }
        }
    }

    @action getLiveEvents = async (options = {}) => {
        this.events = [];
        try {
            let response = await get(
                `/events.json?show_closed=${false}`, options);

            this.events = response.data.events;
        } catch (err) {
            console.log(err);
        }
    }
    @action getFeaturedEvents = async (options = {}) => {
        this.featuredevents = [];
        try {
            let response = await get(`/events/featured_events.json`);

            this.featuredevents = response.data.events;
            // console.log(JSON.stringify(this.featuredevents), "Hello");
        } catch (err) {
            console.log(err);
        }
    }

    @action getLatestPurchaseditems = async () => {
        // this.latest_purchased_items = [];
        try {
            let response = await get(`/event_items/latest_purchased_items.json`);
            this.latest_purchased_items = response.data.latest_purchased_items;
        } catch (err) {
            console.log(err);
        }
    }




   

    @action getUpcomingEvents = async () => {
        this.upcomingevents = [];
        try {
            let response = await get(`/events/upcoming_events.json`);
            this.upcomingevents = response.data.events;
        } 
        catch (err) {
            console.log(err);
        }
    }

    getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item.item.title, item.item.title])).values()]
    }

    @action getFilterMarketPlaceData = async (arrayData) => {
        let tempArray = [];
        try {
            if (arrayData.length == 0) {
                this.Tempeventitems = this.eventitems;  
                return;              
            }
            this.eventitems.map((t) => {
                arrayData.map((f) => {
                    if (t.item.title == f) {
                        tempArray.push(t);
                    }
                })
            })
            this.Tempeventitems=tempArray;
        } catch (err) {
            console.log(err);
        }
    }


    @action getMarketPlaceEventItems = async (data, options = {}) => {
        this.eventitems = [];
        try {
            let response = await get(
                `/market_place/items.json`
            );

            this.eventitems = response.data.event_items;
            this.Tempeventitems = response.data.event_items;
           this.eventitems= this.eventitems.sort(function(a, b){
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
                return 0;
            })
          this.filterDuplicationRemove = this.getUniqueListBy(this.eventitems);
           
        } catch (err) {
            console.log(err);
        }
    }

    @action getEventItems = async (data, options = {}) => {
        this.eventitems = [];
        this.eventitemsdetails = [];
        try {
            let response = await get(
                `/event_items.json?event_id=${data.id}`, options
            );

            this.eventitems = response.data.event_items;
        } catch (err) {
            console.log(err);
        }
    }

    @action getMainWebAppData = async () => {
        this.mainwebappdata = {};
        try {
            let response = await get(
                `/companies/get_main_web_app.json`
            );
            this.mainwebappdata = response.data.main_web_app;
            // console.log(this.mainwebappdata);

        } catch (err) {
            console.log(err);
        }
    }


    @action getSpecificEventItems = async (data, options = {}) => {
        this.eventitems = [];
        this.eventitemsdetails = [];
        try {
            let response = await get(
                `/event_items.json?event_id=${data.id}&item_type_id=${data?.item_type_id}`, options
            );

            this.eventitems = response.data.event_items;
        } catch (err) {
            console.log(err);
        }
    }

    @action setPortfolioFromLocalStorage = async (data) => {
        this.eventitemsdetails = [];
        try {
            let parseData = JSON.parse(data)
            this.eventitemsdetails.push(parseData?.item ? parseData : parseData?.event_item?.short_event_item);
            return parseData?.event_item?.short_event_item?.item?.sku_id;
        } catch (err) {
            console.log(err);
        }
    }

    @action getSingleEventItems = async (data, options = {}) => {
        this.eventitemsdetails = [];

        try {
            let response = {};
            if (data.marketplace) {
                response = await get(`/market_place/event_item_id.json?event_item_id=${data?.event_item_id}`, options)
            }
            else {
                response = await get(
                    `/event_items/event_item_id.json?item_type_id=${data?.item_type_id}&event_item_id=${data?.event_item_id}`, options
                );
            }

            this.eventitemsdetails.push(response.data.event_item);
            return response?.data?.event_item?.item?.sku_id ?? 0
        } catch (err) {
            console.log(err);
        }
    }


    @action buyEventItems = async (data, pathId, options = {},quantity=1) => {
        this.eventitems = [];
        try {
            let response;
            for(let i=0; i<quantity; i++) {
                 response = await post(
                    `/event_items/${pathId}/buy.json`, data, options
                );
            }
            this.errors = "";
            this.buyitems = response.data;
        } catch (err) {
            this.errors = err.response.data;

        }
    }


    
  

    @action getCarouselEventItems = async (options = {}) => {
        this.carouseleventitems = [];
        try {
            let response = await get(
                `/event_items/featured_items.json`, options
            );
            let data = []
            for (let i of response.data.event_items) {

                if (i.closes_at > new Date().getTime() / 1000) {
                    data.push(i)
                }
            }
            this.carouseleventitems = data;
        } catch (err) {
            console.log(err);
        }
    }

    @action sortItems = (attribute, asc) => {
        if (asc) {
            this.Tempeventitems.replace(this.Tempeventitems.slice().sort((a, b) => (a[attribute] - b[attribute])))
            return;
        }

        this.Tempeventitems.replace(this.Tempeventitems.slice().sort((a, b) => (b[attribute] - a[attribute])))
    }
}

export default EventStore;
export const EventStoreContext = createContext(new EventStore())