import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { observer, inject, PropTypes } from "mobx-react";
import pic1 from "../img/thumb.png";
import pic2 from "../img/newpic2.png";
import { EventItemId } from "../../../../helpers/ParamsIdHelper";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import NumeralHelper from "../../../../helpers/NumeralHelper";
const CountDown = React.lazy(() => import("./CountDown"));
const FeaturedEventsCarouselComponent = ({ Location, Logo, pcolor, events }) => {

  const items =
    Location?.map((fe) => {
      const title = fe.event_name ? fe.event_name : fe.title;
      return {
        id: `${fe.id}`,
        src: fe?.photo?.photo?.url ?? "",
        end_at: `${fe.end_at}`,
        altText: `${fe?.title ?? ""}`,
        thumb: fe?.photo?.photo.thumb.url ?? "",
        caption: `${fe.description}`,
        itemtype: `${EventItemId(fe.item_type)}`,
        price: `${fe.price}`,
        title: title,
      };
    }) || [];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = pic2;
  };
  const addDefaultThumb = (ev) => {
    ev.target.src = pic1;
  };
  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className="row justify-content-center">
          <div
            className="card inner-card"
            style={{
              background: "transparent",
              marginTop: "0px",
              border: "none",
              boxShadow: "none",
            }}
          >

            <div
              className="card-body inner-card-body"
              style={{
                height: "420px",
                border: "none",
                boxShadow: "none",
                width: "80%",
                background: "transparent",
              }}
            >
              <div className="card-text">
                <div
                  className="row justify-content-center"
                  style={{ background: "#fff", height: "420px" }}
                >
                  <div className="col-md-6 main-slide-image">
                    <div className="col-md-12 slide-image-overflow">
                      <div className="row justify-content-end">
                        <Link to={`/events/${item.id}`}>
                          <img
                            className=" mt-4 mb-5 bg-white rounded"
                            style={{
                              maxHeight: "100%",
                              width: "100%",
                              paddingTop: "1.5rem",
                            }}
                            src={item.src}
                            onError={addDefaultSrc}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 slide-text-section"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          className="logo-style"
                          style={{ height: "80px", marginBottom: "3px" }}
                          src={`${Logo}`}
                          onError={addDefaultThumb}
                        />
                        <h4
                          className="card-title mb-5"
                          style={{ fontSize: "16px", color: `${pcolor}` }}
                        >
                          {item.altText}
                        </h4>
                        <div className="row">
                          {item.end_at == "null" ? (
                            <h2>Time Not Defined</h2>
                          ) : (
                            <>
                              <div className="col-12">
                                <hr />
                                <h4
                                  style={{
                                    paddingBottom: "4px",
                                    paddingTop: "4px",
                                  }}
                                >
                                  TIME REMAINING
                                </h4>
                              </div>
                              <div className="col-12 mt-3">
                                <h2
                                  style={{
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  <CountDown
                                    deadline={new Date(item.end_at * 1000)}
                                  />
                                </h2>
                                <hr />

                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div className="col-md-12 mb-5">
      {slides.length > 0 ? (
        <>
          <CarouselControl
            style={{ color: "black" }}
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <div className="row justify-content-center">
            <div className="card carouselstyle">
              <div
                className="card-body"
                style={{ background: "transparent", boxShadow: "none" }}
              >
                <Carousel
                  interval={false}
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  {slides}
                </Carousel>
              </div>
            </div>
          </div>
          <CarouselControl
            style={{ color: "black", background: "black", marginTop: "7%" }}
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
        </>
      ) :
        <div className="col-md-12" style={{ textAlign: 'center', paddingTop: '15%' }}>
          <h2 style={{ color: 'white' }}>No Featured Events Available</h2>
        </div>
      }
    </div>

  );
};


export default FeaturedEventsCarouselComponent;
