import React from 'react';
import './BlockPageSpinner.scss';

const BlockPageSpinner = ({ loading }) => {
  return (
    <>
      <div className={`blockPage${loading ? ' blockPage--visible' : ''}`} />
      <div className={`loaderContainer${loading ? ' loaderContainer--visible' : ''}`}>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default BlockPageSpinner;
