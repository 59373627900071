import { observable, action } from "mobx";
import { get, post } from "../services/ajax.service";
import TransferModal from './../views2/dashboard/Design/Components/transferModal';

class MarketPlace {

    @observable marketplace = [];
    @observable errors = "";
    @observable error = {};
    @observable transactions = [];
    @observable hundred_transactions = [];
    @observable Tempmarketplace = [];








    @action getMarketPlace = async (options = {}) => {
        this.marketplace = [];
        this.errors = "";
        try {
            let response = await get(
                `/market_place/my_items.json`, options
            );
            this.errors = "";
            this.marketplace = response.data.market_place;
            this.Tempmarketplace = response.data.market_place;
        } catch (err) {
            this.errors = err.response.data.errors[0]
            console.log(JSON.stringify(err.response.data.errors[0]));
        }
    }

    @action sortItems = (attribute, asc) => {
        if (asc) {
            this.Tempmarketplace.replace(this.Tempmarketplace.slice().sort((a, b) => (a[attribute] - b[attribute])))
            return;
        }

        this.Tempmarketplace.replace(this.Tempmarketplace.slice().sort((a, b) => (b[attribute] - a[attribute])))
    }


    @action getTransactionHistory = async (data, options = {}) => {

        this.transactions = [];
        this.errors = "";
        try {
            let response = await get(
                `/market_place/transaction_history.json?&sku_id=${data.sku_id}`
            )
            if (response.data.transactions && response.data.transactions.length > 0) {
                response.data.transactions.sort((a, b) => a.created_at - b.created_at)
            }
            this.transactions = response.data.transactions;
        } catch (err) {
            // console.log(err);
        }
    }



    @action getHundredTransactions = async (data) => {

        try {
            let response = await get(
                `/market_place/transaction_history.json?per_page=100&&offset=${data.offset}`
            )
            // this.hundred_transactions = [];

            this.hundred_transactions = [...this.hundred_transactions, ...response.data.transactions];
        } catch (err) {
        }
    }

    @action checkEventOwnerShip = async (data) => {
        let response = await get(
            `/market_place/is_owner.json?sku_id=${data.sku_id}`
        )
        return response.data;
    }
    @action MarketResale = async (data) => {

        this.error = null;
        try {
            let response = await post(
                `/market_place/resale.json`, data
            )
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }
    @action TransferData = async (data) => {

        this.error = null;
        try {
            let response = await post(
                `/market_place/transfer.json`, data

            )
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }

    @action MarketTransfer = async (data) => {
        this.error = null;
        try {
            let response = await post(
                `/market_place/transfer.json`, data
            )
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }

    @action MarketClaimUtility = async (data) => {
        this.error = null;
        try {
            let response = await post(
                `/market_place/claim_utility.json`, data
            )
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors);
        }
    }

    @action MarketGift = async (data) => {
        const { email, event_item_id, sku_id } = data
        this.error = null;
        try {
            let response = await get(
                `/event_items/user_send_gift_item.json?email=${email}&event_item_id=${event_item_id}&sku_id=${sku_id}`
            )
            return response.data;
        } catch (err) {
            this.error = err.response.data
            console.log(err.response.data.errors)
        }
    }

}

export default MarketPlace;
