const CompanyUrlName = (type) => {
  switch (type) {
    case "igi":
      return "igi";
    case "pll":
      return "pll";
    case "demo":
      return "demo";
    case "pelicans":
      return "pelicans";
    case "pfhof":
      return "pfhof";
    case "bucks":
      return "bucks";
    case "saints":
      return "saints";
    case "cavaliers":
      return "cavaliers";
    case "pistons":
      return "pistons";
    case "chiefs":
      return "chiefs";
    case "chargers":
      return "chargers";
    case "kings":
      return "kings";
    case "jaguars":
      return "jaguars";
    case "nll":
      return "nll";
    case "bengals":
      return "bengals";
    case "ravens":
      return "ravens";
    case "koops":
      return "koops";
    case "texans":
      return "texans";
    default:
      return "igi";
  }
}
export { CompanyUrlName }