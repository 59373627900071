import React, { useContext } from 'react';
import ItemCard from '../ItemCard/MktItemCard';
import { EventItemId } from '../../../../../helpers/ParamsIdHelper';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../../Design/Marketplace.scss';

const ZoomCarousel = ({ colors, items, logo, company_url_name , AuthStore}) => {
  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const cardItem =

    items?.map((ev) => {
      let PriceType = ev.price;
      console.log(ev.price)
      if (ev.item_type == "Bid Only") {
        PriceType = ev?.price;
        if (ev.current_bid?.price) {
          PriceType = ev?.current_bid?.price;
        }
      }
      const item = {
        id: `${ev.id}`,
        AuthStore,
        image: ev.item.is_digital ? ev.item?.digital_item?.photo ?? '' : ev.item.photos[0]?.photo?.photo?.url ?? '',
        deadline: new Date(ev.closes_at * 1000),
        altText: `${ev.item.title}`,
        video: ev.item.is_digital ? ev.item?.digital_item?.preview_asset ?? '' : ev.item?.videos?.[0]?.video_url ?? '',
        thumb: ev.item.photos[0]?.photo?.photo.thumb ?? '',
        previewAsset: ev.item?.digital_item?.preview_asset,
        description: `${ev.description}`,
        itemType: ev.item_type,
        price: `${PriceType != null ? +PriceType : "Free"}`,
        title: ev.item.title ?? "",
        colors: colors,
        itemDetailUrl: `/platforms/${company_url_name}/items/${EventItemId(ev.item_type)}/${ev.id}`,
        masterQuantity: ev.item.is_digital ? ev.item.digital_item?.master_quantity : 0,
        skuNumber: ev.item.is_digital ? ev.item.asset_index : 0,
        Quantity: ev.quantity ? ev.quantity : 0,
        Membership: ev.item?.digital_item?.is_membership,
        channel_name: ev.channel_name
      };
      return (
        <ItemCard
          {...item}
          
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            maxWidth: '60rem',
            margin: 'auto',
            padding: '1rem',
          }}
        />
      );
    }) || [];

  return (
    <div className=" carouselContainer" style={{ backgroundImage: `url(${logo})`, backgroundColor: `${colors.secondary}` }}>
      <Carousel
        removeArrowOnDeviceType={['mobile', 'tablet']}
        responsive={responsive}
        slidesToSlide={1}
        autoPlay
        autoPlaySpeed={6000}
        infinite
        itemClass="d-flex"
      >
        {cardItem}
      </Carousel>
    </div>
  );
};

export default ZoomCarousel;
